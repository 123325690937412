/* utilities */
.lol3 {
  padding: 20px;
  position: absolute;
  height: 100%;
  min-width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/images/1.gif);
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: rgb(191, 191, 191);

  @media screen and (max-width: 20em) {
    height: 0;
  }
}

.atitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.acontent.show {
  height: auto;
  max-height: 300px;
  transition: all 1s ease;
}

.acontent {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;
}

.aflex {
  display: flex;
  gap: var(--gap, 1rem);
}

.aflow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1em);
}

.abg-primary-400 {
  background: hsl(var(--clr-primary-400));
}
.abg-secondary-400 {
  background: hsl(var(--clr-secondary-400));
}
.abg-secondary-500 {
  background: hsl(var(--clr-secondary-500));
}

.abg-neutral-100 {
  background: hsl(var(--clr-neutral-100));
}

.atext-neutral-100 {
  color: hsl(var(--clr-neutral-100));
}
.atext-secondary-400 {
  color: hsl(var(--clr-secondary-400));
}
.aborder-primary-400 {
  border: 2px solid #a775f1;
}

.aname {
  font-size: xx-small;
}

/* components */
.atestimonial-grid {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "one" "two" "three" "four" "five" "six" "seven" "eight";

  width: min(95%, 70rem);
  margin-inline: auto;

  @media screen and (min-width: 1100px) {
    grid-template-areas:
      "one one two"
      "three three five"
      "four four six";
  }
}

.atestimonial-grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "two one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.atestimonial-grid-3 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.atestimonial {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.atestimonial-2 {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.atestimonial-2:nth-child(1) {
  grid-area: one;
}

.atestimonial:nth-child(1) {
  grid-area: one;
}
.atestimonial:nth-child(2) {
  grid-area: two;
}
.atestimonial:nth-child(3) {
  grid-area: three;
}
.atestimonial:nth-child(4) {
  grid-area: four;
}
.atestimonial:nth-child(5) {
  grid-area: five;
}
.atestimonial:nth-child(6) {
  grid-area: six;
}
.atestimonial:nth-child(7) {
  grid-area: seven;
}
.atestimonial:nth-child(8) {
  grid-area: eight;
}

.atestimonial.quote {
  background-repeat: no-repeat;
  background-position: top right 10%;
}

.atestimonial img {
  width: 1.75rem;
  aspect-ratio: 1;
  border-radius: 50%;
}

.atestimonial .name {
  font-size: var(--fs-200);
  font-weight: var(--fw-200);
  line-height: 1;
  text-transform: uppercase;
}

.atestimonial .number {
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
}

.atestimonial .col {
  align-items: center;
}

.atestimonial .position {
  font-size: var(--fs-300);
  opacity: 0.5;
}

.atestimonial > p:first-of-type {
  font-size: var(--fs-500);
  line-height: 1.2;
}

.atestimonial > p:last-of-type {
  opacity: 0.7;
}

@media screen and (max-width: 600px) {
  .lol3 {
    height: max-content;
  }
}

@media screen and (max-width: 700px) {
  .lol3 {
    height: max-content;
  }
}

@media screen and (max-width: 900px) {
  .lol3 {
    height: max-content;
  }
}

@media screen and (max-width: 1020px) {
  .lol3 {
    height: max-content;
  }
}

@media screen and (min-width: 38em) {
  .atestimonial-grid {
    grid-template-areas:
      "one"
      "two"
      "three"
      "four"
      "five"
      "six"
      "seven"
      "eight";
  }
  .atestimonial .name {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: 1;
    text-transform: uppercase;
  }

  .atestimonial .number {
    font-size: 20px;
    line-height: 1;
  }
}

@media screen and (min-width: 65em) {
  .lol3 {
    height: max-content;
  }
}

@media screen and (min-width: 75em) {
  .atestimonial-grid {
    grid-template-areas:
      "one two two four four"
      "one three five seven seven"
      "one six eight eight eight";
  }
  .atestimonial-grid-2 {
    grid-template-areas: "two two two two one";
  }

  .acenter {
    display: absolute;
  }
  .lol3 {
    height: 100vh;
  }
}

@font-face {
  font-family: minecraft;
  src: url(../src/font/upheavtt.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: minecraft;
  color: white;
  letter-spacing: 0.05rem;
}

svg {
  width: 50px;
  color: white;
}

p {
  color: rgb(191, 191, 191);
}

h1 h2 {
  color: white;
}

.arrow {
  position: relative;
  margin-top: 1rem;
  height: 3rem;
  width: 3rem;
  -webkit-animation: arrow 2.5s infinite;
  animation: arrow 2s infinite ease normal;

  @media only screen and (max-width: 300px) {
    height: 1rem;
  width: 1rem;
  }
}

@keyframes arrow {
  from {
    top: 0px;
  }
  to {
    top: 30px;
  }
}

/* utilities */
.lol2 {
  padding: 20px;
  position: absolute;
  height: 100%;
  min-width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/images/2.gif);
  background-color: #ccc;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: rgb(191, 191, 191);

  @media screen and (max-width: 20em) {
    height: 0;
  }
}

.btitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.bcontent.show {
  height: auto;
  max-height: 200px;
  overflow: hidden;
  transition: all 1s ease;
}

.bcontent {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;
}

.bflex {
  display: flex;
  gap: var(--gap, 1rem);
}

.bflow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1em);
}

.bbg-primary-400 {
  background: hsl(var(--clr-primary-400));
}
.bbg-secondary-400 {
  background: hsl(var(--clr-secondary-400));
}
.bbg-secondary-500 {
  background: hsl(var(--clr-secondary-500));
}

.bbg-neutral-100 {
  background: hsl(var(--clr-neutral-100));
}

.btext-neutral-100 {
  color: hsl(var(--clr-neutral-100));
}
.btext-secondary-400 {
  color: hsl(var(--clr-secondary-400));
}
.bborder-primary-400 {
  border: 2px solid #a775f1;
}

.bname {
  font-size: xx-small;
}

/* components */
.btestimonial-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: "one" "two" "three" "four";

  width: min(95%, 70rem);
  height: 100%;
  margin-inline: auto;

  @media screen and (min-width: 1100px) {
    grid-template-areas:
      "one one two"
      "three three five"
      "four four six";
  }
}

.btestimonial-grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "two one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.btestimonial-grid-3 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.btestimonial {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.btestimonial-2 {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.btestimonial-2:nth-child(1) {
  grid-area: one;
}

.btestimonial:nth-child(1) {
  grid-area: one;
}
.btestimonial:nth-child(2) {
  grid-area: two;
}
.btestimonial:nth-child(3) {
  grid-area: three;
}
.btestimonial:nth-child(4) {
  grid-area: four;
}
.btestimonial:nth-child(5) {
  grid-area: five;
}
.btestimonial:nth-child(6) {
  grid-area: six;
}
.btestimonial:nth-child(7) {
  grid-area: seven;
}
.btestimonial:nth-child(8) {
  grid-area: eight;
}

.btestimonial.quote {
  background-repeat: no-repeat;
  background-position: top right 10%;
}

.btestimonial img {
  width: 1.75rem;
  aspect-ratio: 1;
  border-radius: 50%;
}

.btestimonial .name {
  font-size: var(--fs-200);
  font-weight: var(--fw-200);
  line-height: 1;
  text-transform: uppercase;
}

.btestimonial .number {
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
}

.btestimonial .col {
  align-items: center;
}

.btestimonial .position {
  font-size: var(--fs-300);
  opacity: 0.5;
}

.btestimonial > p:first-of-type {
  font-size: var(--fs-500);
  line-height: 1.2;
}

.btestimonial > p:last-of-type {
  opacity: 0.7;
}

@media screen and (max-width: 520px) {
  .lol2 {
    height: max-content;
    width: 100%;
  }
}

@media screen and (min-width: 38em) {
  .btestimonial-grid {
    grid-template-areas:
      "one one two"
      "three four two";
  }
  .btestimonial .name {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: 1;
    text-transform: uppercase;
  }

  .btestimonial .number {
    font-size: 40px;
    line-height: 1;
  }
}

@media screen and (min-width: 75em) {
  .btestimonial-grid {
    grid-template-areas:
      "one two two four"
      "one three three four";
  }
  .btestimonial-grid-2 {
    grid-template-areas: "two two two two one";
  }

  .bcenter {
    display: absolute;
    transform: translate(0%, 25%);
  }
}

.overlay {
  position: fixed;
  width: 0%;
  height: 100vh;
  background: black;
  display: flex;
  right: 0;

  animation: overlay 2s ease;
}

@keyframes overlay {
  0% {
    right: 0;
    width: 0%;
  }
  50% {
    right: 0;
    width: 100%;
  }
  100% {
    right: 100%;
    width: 0%;
  }
}

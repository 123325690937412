/* utilities */

.lol5 {
  padding: 20px;
  position: absolute;
  height: 100vh;
  min-width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/images/4.gif);
  background-color: #ccc;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: rgb(191, 191, 191);

  @media screen and (max-width: 20em) {
    height: 0;
  }
}

.ctitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ccontent.show {
  max-height: 200px;
  width: 200px;
  overflow: hidden;
  transition: all 1s ease;
}

.ccontent {
  color: black;
  max-height: 0;
  width: 200px;
  overflow: hidden;
  transition: all 1s ease;
}

.ccontent2.show {
  max-height: 50px;
  width: 200px;
  overflow: hidden;
  transition: all 1s ease;
}

.ccontent2 {
  color: black;
  max-height: 0;
  width: 200px;
  overflow: hidden;
  transition: all 1s ease;
}

.cflex {
  display: flex;
  gap: var(--gap, 1rem);
}

.cflow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1em);
}

.cbg-primary-400 {
  background: hsl(var(--clr-primary-400));
}
.cbg-secondary-400 {
  background: hsl(var(--clr-secondary-400));
}
.cbg-secondary-500 {
  background: hsl(var(--clr-secondary-500));
}

.cbg-neutral-100 {
  background: hsl(var(--clr-neutral-100));
}

.ctext-neutral-100 {
  color: hsl(var(--clr-neutral-100));
}
.ctext-secondary-400 {
  color: hsl(var(--clr-secondary-400));
}
.cborder-primary-400 {
  border: 2px solid #a775f1;
}

.cname {
  font-size: xx-small;
}

/* components */
.ctestimonial-grid {
  gap: 1rem;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: "one" "two" "three" "four" "five" "six" "seven" "eight";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.ctestimonial-grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "two one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.ctestimonial-grid-3 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "one";

  width: min(95%, 70rem);
  height: min(95%, 70rem);
  margin-inline: auto;
}

.ctestimonial {
  justify-items: center;
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.ctestimonial-2 {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.ctestimonial-2:nth-child(1) {
  grid-area: one;
}

.ctestimonial:nth-child(1) {
  grid-area: one;
}
.ctestimonial:nth-child(2) {
  grid-area: two;
}
.ctestimonial:nth-child(3) {
  grid-area: three;
}
.ctestimonial:nth-child(4) {
  grid-area: four;
}
.ctestimonial:nth-child(5) {
  grid-area: five;
}
.ctestimonial:nth-child(6) {
  grid-area: six;
}
.ctestimonial:nth-child(7) {
  grid-area: seven;
}
.ctestimonial:nth-child(8) {
  grid-area: eight;
}

.ctestimonial.quote {
  background-repeat: no-repeat;
  background-position: top right 10%;
}

.ctestimonial img {
  width: 200px;
  aspect-ratio: 1;
  border-radius: 1rem;
  border: 5px;
}

.ctestimonial .name {
  font-size: var(--fs-200);
  font-weight: var(--fw-200);
  line-height: 1;
  text-transform: uppercase;
}

.ctestimonial .number {
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.ctestimonial .col {
  align-items: center;
}

.ctestimonial .position {
  font-size: var(--fs-300);
  opacity: 0.5;
}

.ctestimonial > p:first-of-type {
  font-size: var(--fs-500);
  line-height: 1.2;
}

.ctestimonial > p:last-of-type {
  opacity: 0.7;
}

@media screen and (max-width: 600px) {
  .lol5 {
    height: max-content;
    width: 100%;
  }
}

@media screen and (min-width: 36em) {
  .ctestimonial-grid {
    grid-template-areas: "one two" "three four";
  }
  .ctestimonial .name {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: 1;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 65em) {
  .ctestimonial-grid {
    grid-template-areas: "one two" "three four";
  }
  .ctestimonial .name {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: 1;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 75em) {
  .ctestimonial-grid {
    grid-template-areas: "one two three four";
  }
  .ctestimonial-grid-2 {
    grid-template-areas: "two two two two one";
  }

  .ccenter {
    position: relative;
    transform: translate(0%, 25%);
  }
}

.button {
  margin: 10px;
  width: 20px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: white;
  background-color: #a775f1;
  padding: 0.5em 1.25em;
  border-radius: 0.5rem;
  align-content: center;
}

.button:hover,
.button:focus {
  background-color: white;
  color: black;
}

/* zzyak */
.card {
  color: white;
  background-image: url(../../components/images/zzyak.png);
  background-size: cover;
  padding: 10rem 0 0;
  width: 35vh;
  height: 35vh;
  border-radius: 0.5rem;
  overflow: hidden;

  transition: transform 0.5s ease;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  transform: translateY(20%);
  transition: transform 0.5s ease;
}

.card:focus-within .card-content {
  transition-delay: 0ms;
}

.card-content > *:not(.card-title) {
  opacity: 0;
  transition: opacity 0.5s linear;
}
.card:hover .card-content > *:not(.card-title),
.card:focus-within .card-content > *:not(.card-title) {
  opacity: 1;
  transition-delay: 1s;
}

.card:hover .card-content,
.card:focus-within .card-content {
  transform: translateY(-45%);
  transition-delay: 0.5s;
}

.card-title {
  position: relative;
  width: max-content;
  text-transform: uppercase;
}

.card-title::after {
  content: "";
  position: absolute;
  height: 3px;
  width: calc(110% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: #a775f1;
  transform: scaleX(0);
  transition: transform 0.5s ease;
  transform-origin: left;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-body {
  margin-top: 10px;
  color: white;
}

/* @media screen and (max-width: 1500px) {
  .card-content {
    transform: translateY(10%);
    transition: transform 0.5s ease;
  }
  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 0.5s linear;
  }
  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 1s;
  }
  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(-60%);
    transition-delay: 0.5s;
  }
  .card:focus-within .card-content {
    transition-delay: 0ms;
  }
  .card-title::after {
    transform: scaleX(0);
  }
} */

/* utilities */
.lol4 {
  padding: 20px;
  position: absolute;
  height: auto;
  min-width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/images/3.gif);
  background-color: #ccc;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: rgb(191, 191, 191);

  @media screen and (max-width: 20em) {
    height: 0;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content.show {
  height: auto;
  max-height: 500px;
  overflow: hidden;
  transition: all 1s ease;
}

.content {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
}

.flow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1em);
}

.bg-primary-400 {
  background: hsl(var(--clr-primary-400));
}
.bg-secondary-400 {
  background: hsl(var(--clr-secondary-400));
}
.bg-secondary-500 {
  background: hsl(var(--clr-secondary-500));
}

.bg-neutral-100 {
  background: hsl(var(--clr-neutral-100));
}

.text-neutral-100 {
  color: hsl(var(--clr-neutral-100));
}
.text-secondary-400 {
  color: hsl(var(--clr-secondary-400));
}
.border-primary-400 {
  border: 2px solid #a775f1;
}

.name {
  font-size: xx-small;
}

/* components */
.testimonial-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: "one" "two" "three" "four" "five" "six";

  width: min(95%, 70rem);
  margin-inline: auto;

  @media screen and (min-width: 1100px) {
    grid-template-areas:
      "one one two"
      "three three five"
      "four four six";
  }
}

.testimonial-grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "two one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.testimonial-grid-3 {
  display: grid;
  gap: 1.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "one";

  width: min(95%, 70rem);
  margin-inline: auto;
}

.testimonial {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.testimonial-2 {
  font-size: var(--fs-400);
  padding: 20px;
  border-radius: 0.5rem;
}

.testimonial-2:nth-child(1) {
  grid-area: one;
}

.testimonial:nth-child(1) {
  grid-area: one;
}
.testimonial:nth-child(2) {
  grid-area: two;
}
.testimonial:nth-child(3) {
  grid-area: three;
}
.testimonial:nth-child(4) {
  grid-area: four;
}
.testimonial:nth-child(5) {
  grid-area: five;
}
.testimonial:nth-child(6) {
  grid-area: six;
}

.testimonial.quote {
  background-repeat: no-repeat;
  background-position: top right 10%;
}

.testimonial img {
  width: 1.75rem;
  aspect-ratio: 1;
  border-radius: 50%;
}

.testimonial .name {
  font-size: var(--fs-200);
  font-weight: var(--fw-200);
  line-height: 1;
  text-transform: uppercase;
}

.testimonial .number {
  font-size: 40px;
  line-height: 1;
}

.testimonial .col {
  align-items: center;
}

.testimonial .position {
  font-size: var(--fs-300);
  opacity: 0.5;
}

.testimonial > p:first-of-type {
  font-size: var(--fs-500);
  line-height: 1.2;
}

.testimonial > p:last-of-type {
  opacity: 0.7;
}

@media screen and (max-width: 600px) {
.lol4 {
  height: auto;
}
}

@media screen and (min-width: 38em) {
  .testimonial-grid {
    grid-template-areas:
      "one four"
      "two five"
      "three six"
      "three six";
  }
  .testimonial .name {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: 1;
    text-transform: uppercase;
  }

  .testimonial .number {
    font-size: 40px;
    line-height: 1;
  }
}

@media screen and (min-width: 75em) {
  .testimonial-grid {
    grid-template-areas:
      "one two three three three"
      "four four five five six";
  }
  .testimonial-grid-2 {
    grid-template-areas: "two two two two one";
  }

  .center {
    position: absolute;
    height: 100%;
  }

  .lol4 {
    height: 100vh;
  }
}
